import React from "react"
import Layout from "../components/Layout"
import Fb from "../images/Facebook-Icon.png"
import Insta from "../images/Instagram-Icon.png"
import Twit from "../images/Twitter-Icon.png"
import You from "../images/Youtube-Icon.png"
import Loc from "../images/Location-Icon.png"
import Phone from "../images/Phone-2.png"
import PhoneLady from "../images/Phone1.png"
import PhoneRing from "../images/Phone-Ring.png"
import PhoneArrow from "../images/Phone-Ring-Arrow.png"
import Hi from "../images/Hi-Pop-Up.png"

const Contact = () => {
  return (
    <div>
      <Layout>
        <div class="">
          <section class="text-white body-font font-quick overflow-hidden">
            <div class="container px-5 mb-4 mx-auto">
              <div class="lg:w-full mx-auto py-5 flex flex-wrap items-center">
                <div class="lg:w-1/2 w-full lg:pr-10 mb-6 lg:mb-0">
                  <section class="text-white body-font relative">
                    <div class="container mx-auto">
                      <div class="flex flex-col text-center w-full mb-4 animate__animated animate__zoomInDown ">
                        <h1 class="sm:text-2xl text-xl font-medium title-font text-white">
                          To make requests for further informations,
                          <span class="text-main">Contact Us!</span>
                        </h1>
                      </div>

                      <div class="lg:w-full md:w-2/3 mx-auto animate__animated animate__zoomInUp animate__delay-1s">
                        <form
                          action="https://formsubmit.co/subash@agaminfotech.com"
                          method="POST"
                        >
                          <input
                            type="hidden"
                            name="_next"
                            value="https://sageflaretech.com/thankyou"
                          />
                          <input type="hidden" name="_captcha" value="false" />
                          <input
                            type="hidden"
                            name="_subject"
                            value="New Message!!!"
                          />
                          <div class="flex flex-wrap -m-2">
                            <div class="p-2 w-1/2">
                              <div class="relative">
                                <label
                                  for="name"
                                  class="leading-7 text-sm text-white"
                                >
                                  Name
                                  <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    required
                                    class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                  />
                                </label>
                              </div>
                            </div>
                            <div class="p-2 w-1/2">
                              <div class="relative">
                                <label
                                  for="email"
                                  class="leading-7 text-sm text-white"
                                >
                                  Email
                                  <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    required
                                    class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                  />
                                </label>
                              </div>
                            </div>
                            <div class="p-2 w-full">
                              <div class="relative">
                                <label
                                  for="message"
                                  class="leading-7 text-sm text-white"
                                >
                                  Message
                                  <textarea
                                    type="text"
                                    id="message"
                                    name="message"
                                    required
                                    class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out placeholder:text-gray-500"
                                    placeholder="Please describe what you need."
                                  ></textarea>
                                </label>
                              </div>
                            </div>
                            <div class="p-2 w-full">
                              <button type="submit" class="flex mx-auto">
                                <span class="relative rounded-full px-5 py-2.5 overflow-hidden group bg-gradient-to-r from-[#B006BB] via-[#D757A4] to-[#FFA98C] hover:bg-gradient-to-r hover:from-[#FFA98C] hover:to-[#B006BB] text-white transition-all ease-out duration-100">
                                  <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                                  <span class="relative text-xl">
                                    Send Message
                                  </span>
                                </span>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </section>
                </div>
                <div class="lg:w-1/2 w-full lg:h-auto h-64 relative animate__animated animate__fadeInRight animate__delay-2s">
                  <img
                    alt="ecommerce"
                    class="w-full lg:h-auto h-64 object-contain object-center rounded"
                    src={Phone}
                  />
                  <img
                    alt="ecommerce"
                    class="w-full lg:h-[50%] h-[50%] object-contain object-center rounded absolute top-[10%] left-[-2%] lg:left-[-3%] animate-wiggle"
                    src={PhoneLady}
                  />
                  <img
                    alt="ecommerce"
                    class="w-full lg:h-[30%] h-[25%] object-contain object-center rounded absolute top-[-3%] lg:top-[-2%] left-[5%] lg:left-[8%] animate-rocket"
                    src={Hi}
                  />
                  <img
                    alt="ecommerce"
                    class="w-full h-[27%] object-contain object-center rounded absolute top-[53%] left-[-2%] md:left-[-2%] lg:left-[-3%] animate-rotate"
                    src={PhoneRing}
                  />
                  <img
                    alt="ecommerce"
                    class="w-full h-[4%] object-contain object-center rounded absolute top-[65%] left-[3%] md:left-[2%] lg:left-[3%]"
                    src={PhoneArrow}
                  />
                </div>
              </div>
            </div>
          </section>
          <section class="text-gray-600 font-quick bg-white body-font">
            <div class="container px-5 mx-auto flex items-center md:flex-row flex-col">
              <div class="flex flex-col md:ml-0 mx-auto flex-shrink-0">
                <a
                  href="https://www.facebook.com/PlaywithTamil-102059849088584"
                  class="inline-flex py-1 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none animate__animated animate__lightSpeedInLeft"
                >
                  <img src={Fb} alt="fb" class="w-8 h-8" />
                  <span class="ml-4 font-medium title-font">
                    facebook.com/sageflare
                  </span>
                </a>
                <a
                  href="https://www.instagram.com/vigneshagaminfotech/"
                  class="inline-flex py-1 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none animate__animated animate__lightSpeedInLeft animate__delay-1s"
                >
                  <img src={Insta} alt="fb" class="w-8 h-8" />
                  <span class="ml-4 font-medium title-font">
                    instagram.com/sageflare
                  </span>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCGSwoHcc1rM5EOruHPlkZYQ"
                  class="inline-flex py-1 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none animate__animated animate__lightSpeedInLeft animate__delay-2s"
                >
                  <img src={You} alt="fb" class="w-8 h-8" />
                  <span class="ml-4 font-medium title-font">
                    youtube.com/sageflare
                  </span>
                </a>
                <a
                  href="https://twitter.com/playwithtamil"
                  class="inline-flex py-1 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none animate__animated animate__lightSpeedInLeft animate__delay-3s"
                >
                  <img src={Twit} alt="fb" class="w-8 h-8" />
                  <span class="ml-4 font-medium title-font"> @sageflare </span>
                </a>
              </div>

              <div class="flex md:ml-auto md:mr-0 mx-auto items-center flex-shrink-0 space-x-4">
                <a
                  href="https://goo.gl/maps/qsmGeTgAwJDZ1SME8"
                  class="bg-gray-100 py-3 px-5 shadow-lg rounded-lg hover:bg-gray-200 focus:outline-none animate__animated animate__lightSpeedInRight animate__delay-4s"
                >
                  <span class="inline-flex items-center">
                    <img src={Loc} alt="fb" class="w-6 h-8" />
                    <span class="ml-4 flex items-start flex-col leading-none">
                      <span class="title-font font-medium text-2xl text-logo font-pepper">
                        Sageflare tech pvt ltd
                      </span>
                    </span>
                  </span>
                  <span class="font-quick text-left">
                    <p>Space N Beyond</p>
                    <p>Address: 1st Floor, 763, Velachery Rd,</p>
                    <p>above Apple iPlanet Showroom,</p>
                    <p>Camp Road Junction, Rajeshwari Nagar,</p>
                    <p>Tambaram, Chennai, Tamil Nadu 600073</p>
                  </span>
                </a>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </div>
  )
}

export default Contact
